import React, { useContext } from "react";
import Dashboard from "./Dashboard";
import { NavLink } from "react-router-dom";
import TaskInProgess from "./TaskInProgess";
import TaskCompleted from "./TaskCompleted";
import Reconciliated from "./Reconciliated";
import RulesetList from "./RulesetList";
import ImportHistory from "./ImportHistory";
import TaskInProgessIcon from "../assets/images/folder (3).svg";
import DashboardIcon from "../assets/images/Icon Frame.svg";
import ReconciliatedIcon from "../assets/images/Group 37519.svg";
import AIChatbotSVG from "../assets/images/AIChatboat2.svg";
import RulesetIcon from "../assets/images/Group 37509.svg";
import TaskCompletedIcon from "../assets/images/Group 37520.svg";
import ImportHistoryIcon from "../assets/images/import.svg";
import MaskingIcon from "../assets/images/pdf-masking2.svg";
import AdminSettingIcon from "../assets/images/adminSetting.svg";
import Logo from "../assets/images/logo2x.png";
import MenuButtonExpand from "../assets/images/menu-expand@2x.png";
import MenuButtonCollapsed from "../assets/images/menu-collapse.svg";
import { UserDataContext } from "../AppRouter";
import AdminSetting from "./AdminSetting";
import InputFolder from "./InputFolder";
import { useNavigate } from "react-router-dom";
import OutputFolder from "./OutputFolder";
import {
  IconButton,
  Tooltip,
  Fade,
  tooltipClasses,
  styled,
  Toolbar,
} from "@mui/material";
import { decryptStorage } from "../utils";
import MaskingPage from "./MaskingComponents/MaskingPage";
import AIChatbot from "./AIChatbot";
import { Flex } from "../assets/styles/Chatbot";

function Sidebar() {
  const { sidebarView, setSidebarView, showMenu } = useContext(UserDataContext);
  const navigate = useNavigate();
  const components = [
    {
      name: "MAX (AI Chatbot)",
      route: "/chatbot",
      component: <AIChatbot />,
      iconImage: AIChatbotSVG,
      heading: "AI Chatbot",
      access: "Both",
      order: 1,
    },
    {
      name: "Dashboard",
      route: "/dashboard",
      component: <Dashboard />,
      iconImage: DashboardIcon,
      heading: "Dashboard",
      access: "Both",
      order: 2,
      hidden: true,
    },
    {
      name: "PDF Masking",
      route: "/pdfmasking",
      component: <MaskingPage />,
      iconImage: MaskingIcon,
      heading: "PDF Masking",
      access: "User",
      order: 3,
      hidden: true,
    },
    {
      name: "Input Folder",
      route: "/inputfolder",
      component: <InputFolder />,
      iconImage: TaskInProgessIcon,
      heading: "Input Folder",
      access: "Admin",
      order: 4,
      hidden: true,
    },
    {
      name: "Output Folder",
      route: "/outputfolder",
      component: <OutputFolder />,
      iconImage: TaskCompletedIcon,
      heading: "Output Folder",
      access: "Admin",
      order: 5,
      hidden: true,
    },
    {
      name: "Tasks in Progress",
      route: "/taskinprogress",
      component: <TaskInProgess />,
      iconImage: TaskInProgessIcon,
      heading: "Task in Progress",
      access: "User",
      order: 6,
      hidden: true,
    },
    {
      name: "Tasks Completed",
      route: "/taskcompleted",
      component: <TaskCompleted />,
      iconImage: TaskCompletedIcon,
      heading: "Tasks Completed",
      access: "User",
      order: 7,
      hidden: true,
    },
    {
      name: "Reconciled",
      route: "/reconciliated",
      component: <Reconciliated />,
      iconImage: ReconciliatedIcon,
      heading: "Reconciliated",
      access: "Both",
      order: 8,
      hidden: true,
    },
    {
      name: "Ruleset",
      route: "/rulesetlist",
      component: <RulesetList />,
      iconImage: RulesetIcon,
      heading: "Ruleset List",
      access: "Both",
      order: 9,
      hidden: true,
    },
    // {
    //   name: "Import History",
    //   route: "/importhistory",
    //   component: <ImportHistory />,
    //   iconImage: ImportHistoryIcon,
    //   heading: "Import History",
    //   access: "Both",
    //   order: 8,
    // },
    {
      name: "Settings",
      route: "/adminsetting",
      component: <AdminSetting />,
      iconImage: AdminSettingIcon,
      heading: "Admin Setting",
      access: "Admin",
      order: 10,
    },
  ];

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const handleSidebarView = (e) => {
    e.preventDefault();
    if (sidebarView.value) {
      setSidebarView({
        value: false,
        cssClassSidebar: "container",
        icon: MenuButtonExpand,
        message: "Collapse",
        cssLeftContainer: "left-container",
        cssRightContainer: "right-container",
      });
    } else {
      setSidebarView({
        value: true,
        cssClassSidebar: "collapsed-sidebar",
        message: "Expand",
        icon: MenuButtonCollapsed,
        cssLeftContainer: "collapsed-left-container",
        cssRightContainer: "collapsed-right-container",
      });
    }
  };
  const temp = sessionStorage.getItem("token");
  const isAdmin =
    temp && decryptStorage(sessionStorage.getItem("userDetails")).is_admin;
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#0e0e4f",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#000000",
      fontSize: "12px",
    },
  }));
  return (
    <div className={sidebarView.cssClassSidebar}>
      <div className="sidebar" id="sidebar-transition">
        <div className="main-sidebar logo-container">
          <Tooltip title={sidebarView.message} placement="right">
            <button
              className="icon-sidebar sidebar-menu-button"
              onClick={handleSidebarView}
            >
              <img src={sidebarView.icon} alt="menu button" />
            </button>
          </Tooltip>
          {/* <img src={Logo} alt="Foliomax Logo" className="logo" /> */}

          <Flex direction="column" align="center" width="100%">
            <div className="sidebar-logo">
              <img
                src={Logo}
                style={{
                  width: "90px",
                }}
              />
            </div>

            <Flex
              style={{
                fontSize: "1.1rem",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
                letterSpacing: "3.5px",
                margin: "0px",
                position: "relative",
              }}
            >
              FOLIOMAX
              <div className="logo__circle-R logo__circle-sidebar"></div>
            </Flex>
          </Flex>
        </div>
        <ul className="options">
          {components.map((value, index) => {
            if (!isAdmin && value.access === "Admin") return;
            if (isAdmin && value.access === "User") return;
            if (showMenu && value.hidden === true) {
              return;
            }
            return (
              <div className="main-sidebar">
                <div className="icon-sidebar">
                  <StyledTooltip
                    title={value.name}
                    disableHoverListener={
                      sidebarView.message === "Expand" ? false : true
                    }
                    placement="right"
                    arrow
                  >
                    <IconButton>
                      <NavLink
                        key={String(value.name).toLowerCase()}
                        to={String(value.route).toLowerCase()}
                        className={({ isActive }) =>
                          isActive ? "sidebar-icon-selected" : "sidebar-icon"
                        }
                      >
                        <img src={value.iconImage} alt="" />
                      </NavLink>
                    </IconButton>
                  </StyledTooltip>
                </div>
                <li className="sidebar-option li">
                  {value.name !== "Logout" ? (
                    <NavLink
                      key={value.name}
                      to={String(value.route).toLowerCase()}
                      style={{
                        order: `${value.order}`,
                      }}
                      className={({ isActive }) =>
                        isActive ? "sidebar-option selected" : "sidebar-option"
                      }
                    >
                      {value.name}
                    </NavLink>
                  ) : (
                    <div
                      className={({ isActive }) =>
                        isActive ? "sidebar-option selected" : "sidebar-option"
                      }
                      onClick={handleLogout}
                      style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        cursor: "pointer",
                        marginTop: isAdmin ? "85px" : "125px",
                      }}
                    >
                      {value.name}
                    </div>
                  )}
                </li>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default React.memo(Sidebar);
