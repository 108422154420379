import React, { useContext, useEffect, useMemo, useState } from "react";
import { Flex } from "../../assets/styles/Chatbot";
import { GoPlus } from "react-icons/go";
import logo from "../../assets/images/logo2x.png";
import { FaFilePdf } from "react-icons/fa6";
import { IoOptionsOutline } from "react-icons/io5";
import { SlOptionsVertical } from "react-icons/sl";
import { MdDeleteOutline } from "react-icons/md";
import { BiConversation } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoMdInformationCircleOutline } from "react-icons/io";

import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Slider,
  Switch,
  Tooltip,
} from "@mui/material";
import ConversationStarterDialog from "./ConversationStarterDialog";
import { UserDataContext } from "../../AppRouter";
import { BASE_URL, decryptStorage } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import CustomizeInstructionsDialog from "./CustomizeInstructionsDialog";
import { v4 } from "uuid";
import { ReactAWSS3 } from "../../utils/FileUpload";
import { useNavigate } from "react-router-dom";
import DeleteFileModal from "./DeleteFileModal";
import Loader from "./Loader";
import getSingedUrl from "../../utils/SignedUrl";
import PortfolioPromptDialog from "./PortfolioPromptDialog";
import HoldingsPromptDialog from "./HoldingsPromptDialog";

function ChatBotSidebar() {
  const [fileLoading, setFileLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileDeleteLoading, setIsFileDeleteLoading] = useState(false);
  const [openDeleteFile, setopenDeleteFile] = useState({
    open: false,
    data: null,
  });

  // const [selectedFiles, setselectedFiles] = useState([]);
  const [isConversationOpen, setIsConversationOpen] = useState(false);
  const [isPortfolioPromptOpen, setIsPortfolioPromptOpen] = useState(false);
  const [isHoldingsPromptOpen, setIsHoldingsPromptOpen] = useState(false);
  const {
    selectedFiles,
    setSelectedFiles,
    uploadedFiles,
    setUploadedFiles,
    getUploadedFiles,
  } = useContext(UserDataContext);
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  const [progress, setProgress] = useState([]);
  const temp = sessionStorage.getItem("token");
  const role =
    temp && decryptStorage(sessionStorage.getItem("userDetails"))?.role;
  const [anchorEl, setAnchorEl] = useState({
    open: null,
    id: null,
  });
  const open = Boolean(anchorEl.open);
  const handleClick = (event, id) => {
    setAnchorEl({
      open: event.currentTarget,
      id,
    });
  };
  const handleClose = () => {
    setAnchorEl({
      open: false,
      id: null,
    });
  };

  const UploadFilesInChatbot = async (uuid, length) => {
    try {
      let response = await axios.post(
        BASE_URL + "/upload-pdf",
        { uuid: uuid, totalFiles: length },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          // toast.success(response?.data?.data?.message);
          getUploadedFiles();
          setFileLoading(false);
          toast.success(response?.data?.message);
          response?.data?.data?.skipped_files?.forEach((sfile) => {
            toast.error(sfile.reason);
          });
          const arr = response?.data?.data?.uploaded_files?.map((file) => {
            return file.pdf_file_id;
          });
          setSelectedFiles(arr);
        } else {
          return;
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setFileLoading(false);
      error?.response?.data?.data?.skipped_files?.forEach((sfile) => {
        toast.error(sfile.reason);
      });
    }
  };

  const HandleMultipleFileUploads = async (e) => {
    if (!e?.target?.files) {
      return;
    }
    setFileLoading(true);
    let uuid = v4();
    for (let fileItem = 0; fileItem < e.target.files.length; fileItem++) {
      const signedURL = await getSingedUrl(
        e.target.files[fileItem].name,
        e.target.files[fileItem].type,
        navigate,
        uuid
      );
      await ReactAWSS3(
        e.target.files[fileItem],
        uuid,
        setProgress,
        fileItem,
        signedURL
      );
    }

    UploadFilesInChatbot(uuid, e.target.files.length);
  };

  const HandleDeleteFile = async (id) => {
    try {
      setIsFileDeleteLoading(true);
      let response = await axios.post(
        BASE_URL + "/delete-file",
        { file_id: id },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          const { data } = response;

          setSelectedFiles(
            selectedFiles.filter((sfile) => {
              return sfile !== id;
            })
          );

          // setUploadedFiles((prev) => {
          //   return prev.filter((file) => {
          //     return file.pdf_file_id !== id;
          //   });
          // });

          toast.success(response.data.message);
          getUploadedFiles().finally(() => {
            setIsFileDeleteLoading(false);
            setopenDeleteFile({
              open: false,
              data: null,
            });
          });
        } else {
          return;
        }
      }
    } catch (error) {
      // console.log(error.response?.data?.message);
      setIsFileDeleteLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  const HandleFileSelect = (e, file, isChecked) => {
    let skey = [...selectedFiles];

    if (!isChecked) {
      skey.push(file.id);
      setSelectedFiles(skey);
    } else {
      skey = skey.filter((key) => {
        return key !== file.id;
      });
    }

    setSelectedFiles(skey);
  };

  const HandleOpenConversationModal = () => {
    setIsConversationOpen(true);
  };

  const HandleCloseConversationModal = () => {
    setIsConversationOpen(false);
  };

  const HandleOpenModal = () => {
    setIsModalOpen(true);
  };

  const HandleCloseModal = () => {
    setIsModalOpen(false);
  };

  const HandleOpenPortfolioPromptModal = () => {
    setIsPortfolioPromptOpen(true);
  };

  const HandleClosePortfolioPromptModal = () => {
    setIsPortfolioPromptOpen(false);
  };

  const HandleOpenHoldingsPromptModal = () => {
    setIsHoldingsPromptOpen(true);
  };

  const HandleCloseHoldingsPromptModal = () => {
    setIsHoldingsPromptOpen(false);
  };

  useEffect(() => {
    getUploadedFiles(temp);
  }, []);

  const fileData = useMemo(() => {
    return uploadedFiles.map((file) => {
      return {
        key: file.pdf_file_id,
        label: file?.pdf_file_name,
        file: file.pdf_s3_location,
        id: file.pdf_file_id,
      };
    });
  }, [uploadedFiles]);

  const isAllChecked = useMemo(() => {
    return (
      selectedFiles.length > 0 &&
      fileData.every((it) => selectedFiles.includes(it.id))
    );
  }, [selectedFiles]);

  return (
    <>
      <Flex flex={1} direction="column" height="100%" width="100%">
        <Flex
          direction="column"
          align="center"
          width="100%"
          style={{
            marginBottom: "1rem",
          }}
        >
          <div className="sidebar-logo">
            <img src={logo} />
          </div>

          <Flex
            style={{
              fontSize: "1.8rem",
              color: "white",
              fontWeight: "600",
              textAlign: "center",
              letterSpacing: "1px",
              margin: "0px",
              position: "relative",
            }}
          >
            CHATPDF
            <div className="logo__circle-R"></div>
          </Flex>
          <p
            style={{
              fontSize: "0.6rem",
              color: "white",
              fontWeight: "400",
              textAlign: "center",
              margin: "0px",
            }}
          >
            powered by{" "}
            <span
              style={{
                fontWeight: "bold",
                margin: "0px",
              }}
            >
              FOLIOMAX
            </span>
          </p>
        </Flex>
        <Flex
          className="drop-pdf-box"
          flex="1"
          height="100%"
          width="100%"
          style={{
            marginTop: "1rem",
          }}
        >
          <Flex
            align="center"
            justify="center"
            direction="column"
            className="dragger"
            width="100%"
          >
            {fileLoading ? (
              <Loader />
            ) : (
              <>
                <p className="drop-new-chat">
                  <GoPlus
                    style={{
                      fontSize: "1.2rem",
                    }}
                  />
                  New Files
                </p>
                <input
                  type="file"
                  name="files"
                  multiple
                  onChange={HandleMultipleFileUploads}
                />
                <p className="drop-new-pdf">Click here to upload</p>
              </>
            )}
          </Flex>
        </Flex>

        <Flex
          direction="column"
          width="100%"
          height="100%"
          padding="10px"
          className="file_list_box"
        >
          <Flex
            width="100%"
            align="center"
            justify={"space-between"}
            style={{
              marginTop: "0.65rem",
            }}
          >
            <p
              className="file_list_title"
              style={{
                color: "white",
              }}
            >
              <FaFilePdf
                style={{
                  color: "white",
                  fontSize: "1.25rem",
                }}
              />
              Your Uploaded Files
            </p>

            <Tooltip title="Menu" placement="right">
              <Button
                className="file_list_btn"
                id="dropdown-button"
                aria-controls={dropdown ? "dropdown-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={dropdown ? "true" : undefined}
                onClick={(e) => {
                  setDropdown(e.currentTarget);
                }}
              >
                <IoOptionsOutline
                  style={{
                    color: "black",
                    fontSize: "1.1rem",
                    pointerEvents: "none",
                  }}
                />
              </Button>
            </Tooltip>

            <Menu
              id="dropdown-menu"
              anchorEl={dropdown}
              open={Boolean(dropdown)}
              onClose={() => {
                setDropdown(false);
              }}
              MenuListProps={{
                "aria-labelledby": "dropdown-button",
              }}
            >
              <MenuItem>
                <Flex
                  gap="8px"
                  align="center"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "500",
                  }}
                >
                  <Switch
                    checked={isAllChecked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newSelectkey = fileData?.map((it) => {
                          return it.id;
                        });

                        setSelectedFiles(newSelectkey);
                      } else {
                        setSelectedFiles([]);
                      }
                    }}
                  />
                  Select all files
                </Flex>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  HandleOpenModal();
                  setDropdown(false);
                }}
              >
                <Flex
                  gap="8px"
                  align="center"
                  style={{
                    fontSize: "0.7rem",
                    fontWeight: "500",
                  }}
                >
                  <BiConversation
                    style={{
                      fontSize: "1.2rem",
                      // color: "#1677ff",
                    }}
                  />
                  Customize Instructions
                </Flex>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  HandleOpenConversationModal();
                  setDropdown(false);
                }}
              >
                <Flex
                  gap="8px"
                  align="center"
                  style={{
                    fontSize: "0.7rem",
                    fontWeight: "500",
                  }}
                >
                  <FaEdit
                    style={{
                      fontSize: "1.1rem",
                      // color: "#1677ff",
                    }}
                  />
                  Conversation Starters
                </Flex>
              </MenuItem>
              {role === "Admin" && (
                <MenuItem
                  onClick={() => {
                    HandleOpenPortfolioPromptModal();
                    setDropdown(false);
                  }}
                >
                  <Flex
                    gap="8px"
                    align="center"
                    style={{
                      fontSize: "0.7rem",
                      fontWeight: "500",
                    }}
                  >
                    <TbReportSearch
                      style={{
                        fontSize: "1.2rem",
                        // color: "#1677ff",
                      }}
                    />
                    Portfolio Prompt
                  </Flex>
                </MenuItem>
              )}

              {role === "Admin" && (
                <MenuItem
                  onClick={() => {
                    HandleOpenHoldingsPromptModal();
                    setDropdown(false);
                  }}
                >
                  <Flex
                    gap="8px"
                    align="center"
                    style={{
                      fontSize: "0.7rem",
                      fontWeight: "500",
                    }}
                  >
                    <SiMicrosoftexcel
                      style={{
                        fontSize: "1.2rem",
                        // color: "#1677ff",
                      }}
                    />
                    Holdings Prompt
                  </Flex>
                </MenuItem>
              )}
            </Menu>
          </Flex>

          <Flex
            height="100%"
            width="100%"
            direction="column"
            gap={"14px"}
            style={{
              marginTop: "0.3rem",
            }}
          >
            {fileData?.map((file) => {
              const isChecked = selectedFiles?.includes(file.id);

              return (
                <Flex
                  width="100%"
                  padding="5px"
                  align="center"
                  background="#353541"
                  borderRadius="4px"
                  key={file.id}
                  className={`file_list_item ${
                    isChecked ? "file_list_item-active" : ""
                  }`}
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => {
                      HandleFileSelect(e, file, isChecked);
                    }}
                  />
                  <Tooltip title={file.label}>
                    <p
                      className="file_list_item_name"
                      onClick={(e) => {
                        HandleFileSelect(e, file, isChecked);
                      }}
                    >
                      {file.label}
                    </p>
                  </Tooltip>
                  <Flex
                    style={{
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => {
                      handleClick(e, file.id);
                    }}
                  >
                    <SlOptionsVertical
                      style={{
                        color: "white",
                      }}
                    />
                  </Flex>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl.open}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setopenDeleteFile({
                          open: true,
                          data: anchorEl.id,
                        });

                        handleClose();
                        // HandleDeleteFile(anchorEl.id);
                      }}
                    >
                      <Flex
                        gap="5px"
                        align="center"
                        style={{
                          fontSize: "0.85rem",
                        }}
                      >
                        <MdDeleteOutline
                          style={{
                            color: "red",
                            fontSize: "1.3rem",
                          }}
                        />
                        Delete File
                      </Flex>
                    </MenuItem>
                  </Menu>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>

      <CustomizeInstructionsDialog
        isopen={isModalOpen}
        onClose={HandleCloseModal}
      />

      <ConversationStarterDialog
        open={isConversationOpen}
        onClose={HandleCloseConversationModal}
      />

      <PortfolioPromptDialog
        isopen={isPortfolioPromptOpen}
        onClose={HandleClosePortfolioPromptModal}
      />

      <HoldingsPromptDialog
        isopen={isHoldingsPromptOpen}
        onClose={HandleCloseHoldingsPromptModal}
      />

      <DeleteFileModal
        open={openDeleteFile}
        setOpen={setopenDeleteFile}
        HandledeleteFile={HandleDeleteFile}
        loading={isFileDeleteLoading}
      />
    </>
  );
}

export default React.memo(ChatBotSidebar);
