import React, { useEffect, useState } from "react";
import NoData from "./NoData";
import { Flex } from "../../assets/styles/Chatbot";
import PDFCarousel from "./PDFCarousel";
import {
  Button,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Report from "./Report";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";
import ExcelView from "./ExcelView";
import ReportFSModel from "./ReportFSModel";
import { IoOptionsOutline } from "react-icons/io5";
import { AiOutlineExpand } from "react-icons/ai";

// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";

const getBinaryURL = (file) => {
  const binaryString = atob(file.pdf_bin);

  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: "application/pdf" });

  const url = URL.createObjectURL(blob);
  return {
    binary_url: url,
    file_id: file.pdf_file_id,
    file_name: file.pdf_file_name,
  };
};

const convertResponseToValidTable = (responseFormat) => {
  const structuredDate = {
    tables: [],
    "Asset Class Allocation Summary": [],
    "Sub Class Allocation Summary": [],
    "pie-chart": {},
    "Top 10 Holdings": [],
    "Portfolio Summary": [],
    observation: {},
  };
  const report = JSON.parse(responseFormat.data.report);
  report.forEach((obj) => {
    if ("table" in obj && obj.table_name === "Asset Class Allocation Summary") {
      const columns = Object.keys(obj.table[0]);
      const rows = obj.table.map((r) => {
        if (r["Total"] && typeof r["Total"] === "object") {
          const temp = {};
          columns.forEach((col) => {
            if (r["Total"][col]) {
              temp[col] = r["Total"][col];
            } else {
              temp[col] = "";
            }
          });
          return Object.values({
            ...temp,
          });
        }
        return Object.values(r);
      });

      structuredDate["Asset Class Allocation Summary"].push({
        title: obj.table_name,
        columns,
        rows,
      });
    } else if (
      "table" in obj &&
      obj.table_name === "Sub Class Allocation Summary"
    ) {
      const columns = Object.keys(obj.table[0]);
      const rows = obj.table.map((r) => {
        if (r["Total"] && typeof r["Total"] === "object") {
          const temp = {};
          columns.forEach((col) => {
            if (r["Total"][col]) {
              temp[col] = r["Total"][col];
            } else {
              temp[col] = "";
            }
          });
          return Object.values({
            ...temp,
          });
        }
        return Object.values(r);
      });

      structuredDate["Sub Class Allocation Summary"].push({
        title: obj.table_name,
        columns,
        rows,
      });
    } else if ("table" in obj && obj.table_name === "Top 10 Holdings") {
      const columns = Object.keys(obj.table[0]);
      const rows = obj.table.map((r) => {
        if (r["Total"] && typeof r["Total"] === "object") {
          const temp = {};
          columns.forEach((col) => {
            if (r["Total"][col]) {
              temp[col] = r["Total"][col];
            } else {
              temp[col] = "";
            }
          });
          return Object.values({
            ...temp,
          });
        }
        return Object.values(r);
      });

      structuredDate["Top 10 Holdings"].push({
        title: obj.table_name,
        columns,
        rows,
      });
    } else if ("table" in obj && obj.table_name === "Portfolio Summary") {
      const columns = Object.keys(obj.table[0]);
      const rows = obj.table.map((r) => {
        if (r["Total"] && typeof r["Total"] === "object") {
          const temp = {};
          columns.forEach((col) => {
            if (r["Total"][col]) {
              temp[col] = r["Total"][col];
            } else {
              temp[col] = "";
            }
          });
          return Object.values({
            ...temp,
          });
        }
        return Object.values(r);
      });

      structuredDate["Portfolio Summary"].push({
        title: obj.table_name,
        columns,
        rows,
      });
    } else if ("table" in obj) {
      const columns = Object.keys(obj.table[0]);
      const rows = obj.table.map((r) => {
        if (r["Total"] && typeof r["Total"] === "object") {
          const temp = {};
          columns.forEach((col) => {
            if (r["Total"][col]) {
              temp[col] = r["Total"][col];
            } else {
              temp[col] = "";
            }
          });
          return Object.values({
            ...temp,
          });
        }
        return Object.values(r);
      });

      structuredDate.tables.push({
        title: obj.table_name,
        columns,
        rows,
      });
    }

    if ("pie-chart-asset" in obj) {
      structuredDate["pie-chart"]["pie-chart-asset"] = obj["pie-chart-asset"];
    }
    if ("pie-chart-sub" in obj) {
      structuredDate["pie-chart"]["pie-chart-sub"] = obj["pie-chart-sub"];
    }

    if ("High-Level-Observations" in obj) {
      structuredDate["observation"] = obj["High-Level-Observations"];
    }
  });

  return structuredDate;
};

function PdfViewer({ file, id }) {
  const [url, setUrl] = useState(() => {
    return [];
  });
  const [loading, setLoading] = useState(false);
  const [showReportFS, SetShowReportFS] = useState({
    open: false,
    data: null,
    loading: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const HandleCloseReportFS = () => {
    SetShowReportFS({
      open: false,
      data: null,
      loading: false,
    });
  };

  const HandleOpenReportFS = (data, loading) => {
    SetShowReportFS({
      open: true,
      data: data,
      loading: loading,
    });
  };

  const temp = sessionStorage.getItem("token");
  const [tableData, setTableData] = useState(() => {
    return {};
  });

  useEffect(() => {
    if (file.length > 0) {
      let urls = [];
      for (let i = 0; i < file.length; i++) {
        urls.push(getBinaryURL(file[i]));
      }

      setUrl(urls);
    }
  }, [file]);

  const [value, setValue] = useState("pdf");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getReportData = async () => {
      setLoading(true);
      try {
        let response = await axios.post(
          BASE_URL + "/generate-report",
          { pdf_ids: id },
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 200) {
            setTableData(convertResponseToValidTable(response.data));
            setLoading(false);
          } else {
            return;
          }
        }
      } catch (error) {
        toast.error(error.response?.data?.message);
        setLoading(false);
      }
    };
    if (id.length > 0) {
      getReportData();
    }
  }, []);

  return (
    <>
      <Flex direction="column" width="100%" height="100%">
        <Flex align="center" justify="space-between" width="100%">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Pdf"
              value={"pdf"}
              sx={{
                color: "white",
                fontSize: "0.7rem",
              }}
            />
            <Tab
              label="Portfolio Summary"
              value={"report"}
              sx={{
                color: "white",
                fontSize: "0.7rem",
              }}
            />

            <Tab
              label="Holdings Extract"
              value={"excel"}
              sx={{
                color: "white",
                fontSize: "0.7rem",
              }}
            />
          </Tabs>

          {value === "report" && (
            <Flex
              background="white"
              width="fit-content"
              justify="flex-end"
              style={{
                borderRadius: "5px",
              }}
            >
              <Tooltip title="Settings">
                <Button
                  className="file_list_btn"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <IoOptionsOutline
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                    }}
                  />
                </Button>
              </Tooltip>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    HandleOpenReportFS(tableData, loading);
                  }}
                >
                  <Flex gap="5px">
                    <AiOutlineExpand
                      style={{
                        fontSize: "1.2rem",
                        marginRight: "5px",
                      }}
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: "600",
                      }}
                    >
                      View FullScreen
                    </Typography>
                  </Flex>
                </MenuItem>
              </Menu>
            </Flex>
          )}
        </Flex>

        {value === "pdf" && (
          <Flex
            className="pdf-file-viewer"
            height="100%"
            width="100%"
            direction="column"
          >
            {file.length === 0 ? (
              <NoData />
            ) : (
              <>
                {url.length > 0 && (
                  <PDFCarousel key={url.join(",")} files={url} />
                )}
              </>
            )}
          </Flex>
        )}

        {value === "report" && (
          <Flex
            className="pdf-file-viewer"
            height="100%"
            width="100%"
            direction="column"
          >
            <Report tableData={tableData} loading={loading} />
          </Flex>
        )}

        {value === "excel" && (
          <Flex
            className="pdf-file-viewer"
            height="100%"
            width="100%"
            direction="column"
          >
            <ExcelView id={id} />
          </Flex>
        )}
      </Flex>

      {showReportFS.open && (
        <ReportFSModel state={showReportFS} HandleClose={HandleCloseReportFS} />
      )}
    </>
  );
}

export default React.memo(PdfViewer);
