import React, { useEffect, useRef, useState } from "react";
import { Flex } from "../../assets/styles/Chatbot";
import { AiOutlineSend } from "react-icons/ai";
import { IoOptionsOutline } from "react-icons/io5";
import { AiOutlineClear } from "react-icons/ai";
import { MdReadMore } from "react-icons/md";
import Loader from "./Loader";
import ConversationStarters from "./ConversationStarters";
import { TbReport } from "react-icons/tb";
import Search from "./Search";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";
import { Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { VscPreview } from "react-icons/vsc";
import PreviewExcel from "./PreviewExcel";

const MessageLoader = ({ chatRef, scrollRef }) => {
  useEffect(() => {
    if (chatRef.current && scrollRef) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, []);

  return (
    <Flex justify="flex-start" width="100%">
      <Flex
        background="transparent"
        padding="0.5rem 0.8rem"
        borderRadius="10px"
      >
        <div class="chat__loader"></div>
      </Flex>
    </Flex>
  );
};

const Query = ({ query }) => {
  return (
    <Flex justify="flex-end" width="100%">
      <Flex
        className="ai-query"
        background="#1777ff"
        padding="0.5rem 0.8rem"
        borderRadius="10px"
      >
        <span>{query}</span>
      </Flex>
    </Flex>
  );
};

const Response = ({
  response: answer,
  animate,
  chatRef,
  scrollRef,
  pauseRef,
  CausePause,
}) => {
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const speed = 10;

  useEffect(() => {
    if (animate) {
      pauseRef.current.pause = false;
      CausePause(pauseRef.current.pause);
    }
  }, []);

  useEffect(() => {
    if (animate && !pauseRef.current.pause) {
      const typingInterval = setInterval(() => {
        if (currentIndex < answer.length) {
          setTypedText((prevText) => prevText + answer[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(typingInterval);
          pauseRef.current.pause = true;
          CausePause(pauseRef.current.pause);
        }
      }, speed);

      if (chatRef.current && scrollRef) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }

      return () => {
        clearInterval(typingInterval);
      };
    }

    if (chatRef.current && scrollRef) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [answer, speed, currentIndex]);
  return (
    <Flex justify="flex-start">
      <Flex className="ai-response">
        <span
          style={{
            whiteSpace: "pre-line",
            wordBreak: "normal",
          }}
        >
          {animate ? typedText : answer}
        </span>
      </Flex>
    </Flex>
  );
};

const DownloadResponse = ({ response, chatRef, scrollRef, HandlePreview }) => {
  useEffect(() => {
    if (chatRef.current && scrollRef) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, []);

  return (
    <Flex justify="flex-start">
      <Flex className="ai-response">
        <Button
          style={{
            background:
              "linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%)",
            textTransform: "none",
            color: "white",
            width: "auto",
            height: "auto",
            padding: "5px",
            fontSize: "0.7rem",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          onClick={() => {
            HandlePreview(response);
          }}
        >
          <VscPreview
            style={{
              fontSize: "1.1rem",
            }}
          />
          Preview File
        </Button>
      </Flex>
    </Flex>
  );
};

function Chat({ id }) {
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadStarters, setLoadStarters] = useState(false);
  const ChatBoxRef = useRef(null);
  const [history, setHistory] = useState([]);
  const [lastAnimatedQuery, setlastAnimatedQuery] = useState(-1);
  const [conversations, setConversations] = useState([]);
  const [pause, setPause] = useState(false);
  const pauseRef = useRef({
    pause: true,
  });
  const [showStarters, setShowStarters] = useState(() => {
    return id?.length === 0 ? false : true;
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openExcelPreview, setOpenExcelPreview] = useState({
    open: false,
    data: null,
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const temp = sessionStorage.getItem("token");
  // const scrollRef = useRef({
  //   scroll: true,
  // });

  const getConversation = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/get-conversation",
        { pdf_ids: id },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          setHistory(response.data.conversation_history);
        } else {
          return;
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  const getStarters = async () => {
    try {
      setLoadStarters(true);
      let response = await axios.post(
        BASE_URL + "/get-starters",
        { pdf_ids: id },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          setLoadStarters(false);
          setConversations(response.data.data);
        } else {
          return;
        }
      }
    } catch (error) {
      setLoadStarters(false);
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    if (id.length > 0) {
      getConversation();
    }
  }, []);

  useEffect(() => {
    if (id.length > 0) {
      getStarters();
    }
  }, []);

  const HandleSearch = (value) => {
    let res = value;
    if (typeof res === "string" && res.trim().length === 0) {
      return;
    }

    pauseRef.current.pause = true;

    setLoading(true);
    axios
      .post(
        BASE_URL + "/chat",
        {
          query: value,
          pdf_ids: id,
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      )
      .then((response) => {
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 200) {
            setLoading(false);
            setPrompt("");
            setHistory((prev) => {
              const newHistory = [
                ...prev,
                {
                  query: value,
                  response:
                    response.data.type === "text"
                      ? response.data.message
                      : response.data,
                  type: response.data.type === "download" ? "download" : "text",
                },
              ];
              setlastAnimatedQuery(newHistory.length - 1);
              return newHistory;
            });
          } else {
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.message);
      });
  };

  const HandleClearHistory = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/delete-conversation",
        { pdf_ids: id },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          toast.success(response.data?.message);
          setHistory([]);
          setShowStarters(true);
          getConversation();
          getStarters();
          handleClose();
        } else {
          return;
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      handleClose();
    }
  };

  const HandlePause = (value) => {
    const fn = () => {
      setPause(!value);
    };
    fn();
  };

  const HandlePreviewExcel = (answer) => {
    setOpenExcelPreview({
      open: true,
      data: answer,
    });
  };

  const HandleExcelClose = () => {
    setOpenExcelPreview({
      open: false,
      data: null,
    });
  };

  if (!id) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Flex
        className="chat-main"
        direction="column"
        flex={1}
        height={"100%"}
        gap={"10px"}
        style={{
          position: "relative",
        }}
        // onScrollCapture={(e) => {
        //   scrollRef.current.scroll = false;
        // }}
      >
        <Flex
          background="transparent"
          width="100%"
          justify="flex-end"
          style={{
            position: "absolute",
            top: "3px",
            left: "-3px",
          }}
        >
          <Tooltip title="Settings">
            <Button
              className="file_list_btn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <IoOptionsOutline
                style={{
                  color: "black",
                  fontSize: "1.1rem",
                }}
              />
            </Button>
          </Tooltip>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setShowStarters(!showStarters);
              }}
            >
              <Flex gap="5px">
                <MdReadMore
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "5px",
                  }}
                />
                <Typography
                  variant="caption"
                  display="block"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                >
                  Starters
                </Typography>
              </Flex>
            </MenuItem>

            <MenuItem onClick={HandleClearHistory}>
              <Flex gap="5px">
                <AiOutlineClear
                  style={{
                    fontSize: "1.2rem",
                    color: "red",
                    marginRight: "5px",
                  }}
                />
                <Typography
                  variant="caption"
                  display="block"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                >
                  Clear History
                </Typography>
              </Flex>
            </MenuItem>
          </Menu>
        </Flex>

        {/* {history.length === 0 ? (
        <Flex
          vertical
          flex={1}
          className="chat-main-box"
          justify="center"
          align="center"
          gap={"1.5rem"}
        >
          <ConversationStarters
            conversations={conversations}
            HandleSearch={HandleSearch}
          />
        </Flex>
      ) : ( */}
        <Flex
          direction={"column"}
          flex={1}
          className="chat-main-box"
          gap={"1.5rem"}
          ref={ChatBoxRef}
        >
          <Flex justify="flex-start">
            <Flex
              className="ai-response"
              style={{
                boxShadow: "none",
                paddingTop: "23px !important",
              }}
            >
              <span
                style={{
                  fontWeight: "500",
                  color: "lightgray",
                  fontSize: "0.8rem",
                  letterSpacing: "0.1px",
                  lineHeight: "20px",
                  wordBreak: "normal",
                }}
              >
                Meet MAX, your new Analyst! Max is more than just a chatbot;
                he's an Al- driven solution designed specifically for Wealth
                Management and Investment Professionals. Please feel free to ask
                Max any questions you have about the PDFs you've uploaded. You
                can also click on one of the conversation starters to your
                right.
              </span>
            </Flex>
          </Flex>

          {/* {history.length === 0 && (
            <Flex
              style={{
                marginTop: "2rem",
              }}
              align="center"
              justify="center"
            >
              <ConversationStarters
                conversations={conversations}
                HandleSearch={HandleSearch}
              />
            </Flex>
          )} */}

          {/* <React.Fragment key={"some random"}>
            <Query query={"Show the report"} />
            <ReportResponse
              response={{
                data: "some data",
                type: "report",
              }}
              HandleReport={HandleReport}
            />
          </React.Fragment> */}

          {/* <React.Fragment key={"some random"}>
            <Query query={"download data"} />
            <DownloadResponse response={downloadtypeResponse} />
          </React.Fragment> */}

          {history.map((history, i) => {
            return history.type === "text" ? (
              <React.Fragment key={history.query + i}>
                <Query query={history.query} />
                <Response
                  response={history.response}
                  animate={lastAnimatedQuery === i}
                  pauseRef={pauseRef}
                  chatRef={ChatBoxRef}
                  CausePause={HandlePause}
                  scrollRef={true}
                />
              </React.Fragment>
            ) : history.type === "download" ? (
              <React.Fragment key={history.query + i}>
                <Query query={history.query} />
                <DownloadResponse
                  response={history.response}
                  chatRef={ChatBoxRef}
                  scrollRef={true}
                  HandlePreview={HandlePreviewExcel}
                />
              </React.Fragment>
            ) : null;
          })}

          {loading && <MessageLoader chatRef={ChatBoxRef} scrollRef={true} />}
        </Flex>
        {/* )} */}

        <Flex direction={"column"} className="chat-main-input" width="100%">
          <Search
            placeholder="How may I help you?"
            loading={loading}
            value={prompt}
            onChange={(e) => {
              setPrompt(e.target.value);
            }}
            // disabled={true}
            enterButton={<AiOutlineSend />}
            onSearch={HandleSearch}
            pauseRef={pauseRef}
            HandlePause={HandlePause}
            pause={pause}
          />
        </Flex>
      </Flex>

      {showStarters && (
        <Flex
          background="#001529"
          height="100%"
          width="150px"
          style={{
            borderLeft: "2px solid white",
            overflowY: "auto",
          }}
        >
          <ConversationStarters
            conversations={conversations}
            HandleSearch={HandleSearch}
            setShowStarter={setShowStarters}
            grid={false}
            loading={loadStarters}
          />
        </Flex>
      )}

      {openExcelPreview.open && (
        <PreviewExcel
          state={openExcelPreview}
          HandleExcelClose={HandleExcelClose}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(Chat);
